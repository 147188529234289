import { defineComponent } from 'vue'

import { autoInitMdc } from '@/javascript/vuejs/helpers/autoInitMDC'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = { 'change': (value: string): boolean => true }
/* eslint-enable */

export default defineComponent({
	name: 'SelectDropdown',

	props: {
		...on(emits),

		additionalClasses: { default: '', type: String },
		additionalContainerClasses: { default: '', type: String },
		additionalLabelClasses: { default: '', type: String },
		dataTest: { default: '', type: String },
		error: { default: '', type: String },
		hasTooltip: { optional: true, type: Boolean },
		id: { optional: true, type: String },
		infoNotice: { default: '', type: String },
		isDisabled: { default: false, type: Boolean },
		labelText: { default: '', type: String },
		required: { default: false, type: Boolean },
		theme: { default: '', type: String },
		tooltipText: { default: '', type: String },
		value: { optional: true, type: [String, Number] },
	},

	emits,

	data(): Data {
		return { selectWidth: 0 }
	},

	computed: {
		disabled(): boolean {
			return this.theme === 'isDisabled' || this.isDisabled
		},

		selectSelector(): HTMLElement | null {
			return document.querySelector('.dropdown-select')
		},

		setThemeBackground(): string {
			if (this.disabled) {
				return 'mdc-select--disabled'
			} else if (this.theme === 'isSimple' || !this.disabled) {
				return 'mdc-text-field--simple-background'
			}

			return ''
		},
	},

	mounted() {
		autoInitMdc(this.$el)

		if (this.selectSelector) {
			const style = getComputedStyle(this.selectSelector)
			const mdcSelectAnchorWidth: number = parseInt(style?.width)

			this.selectWidth = mdcSelectAnchorWidth
		}
	},

	methods: {
		handleChange(event: Event): void {
			const target = event.target as HTMLInputElement

			this.$nextTick(() => {
				this.$emit('change', target.value)
			})
		},
	},

	render() {
		return (
			<div class={this.additionalContainerClasses}>
				{this.labelText &&
					<label
						class={[
							'dropdown-label',
							this.required ? 'mdc-select--required' : '',
							this.additionalLabelClasses,
						]}
						for={this.id}
						ref={this.id}
					>
						{this.labelText}
					</label>
				}

				<div
					class={[
						'input-line',
						'input-line--full-width',
						'mdc-select--full-width',
						'dropdown',
						'dropdown-select',
						this.setThemeBackground,
						this.hasTooltip ? 'js-tooltip' : '',
					]}
					data-tippy-content={this.tooltipText}
				>
					<select
						aria-disabled={this.disabled}
						aria-labelledby={this.id}
						aria-required={this.required}
						class={this.additionalClasses}
						data-test={this.dataTest}
						disabled={this.disabled}
						id={this.id}
						role="button"
						value={this.value}
						onChange={this.handleChange}
					>
						{this.$slots.default}
					</select>
					<i class="fa fa-arrow-down mdc-select__dropdown-icon" />
				</div>

				{
					this.error &&
					<p
						class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
						data-test="input-number-error-message"
					>
						{this.error}
					</p>
				}

				{
					this.infoNotice &&
					<p class="info-notice info-notice--small info-notice--small-text">{this.infoNotice}</p>
				}
			</div>
		)
	},
})

interface Data {
	selectWidth: number
}
